<template>
  <v-app-bar
    dense flat
    color="#ffffff"
  >
    <v-toolbar-title>  
    </v-toolbar-title>
  </v-app-bar>
</template>

<script>
  export default {
    name: 'StoneHeader',

    data(){
      return {
      }
    }
  }
</script>
