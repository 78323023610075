<template>
  <v-container>
    <StoneHeader />
    <h2>ここはトップページです。</h2>
    <StoneFooter />
  </v-container>
</template>

<script>
/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import StoneHeader from '@/components/organisms/StoneHeader'
import StoneFooter from '@/components/organisms/StoneFooter'

export default {

  name: 'Top',

  components: {
    StoneHeader,
    StoneFooter
  }

}
</script>

<style>
</style>
